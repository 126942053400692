<template>
    <div class="card mt-30">
      <loader-component v-if="loading" />
      <div class="card-body">
        <h1>Budget</h1>
        <div class="row">
          <div class="col-md-12">
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white text-center"
              tbody-class="text-center"
              show-empty
              class="mt-5"
            >
            <template #cell(budget)="data">
                &#2547; {{ data.item.budget }}
            </template>
            </b-table>
          </div>
        </div>
      </div>
      <vue-snotify></vue-snotify>
    </div>
  </template>
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
  export default {
    name: "budget",
  
    data() {
      return {
        items: [
            {
                month: 'Jan',
                year: 2020,
                budget_head: 'Cost of Sales',
                budget: '20000'
            },
            {
                month: 'Feb',
                year: 2020,
                budget_head: 'Selling & Distribution Expenses',
                budget: '30000'
            },
            {
                month: 'Mar',
                year: 2020,
                budget_head: 'Administrative Expenses',
                budget: '40000'
            },
            {
                month: 'April',
                year: 2020,
                budget_head: 'Financial Expenses',
                budget: '20000'
            },
            {
                month: 'May',
                year: 2020,
                budget_head: 'Exchange Gain & Loss',
                budget: '20000'
            },
            {
                month: 'Jun',
                year: 2020,
                budget_head: 'Other Income / Loss',
                budget: '20000'
            },
            {
                month: 'July',
                year: 2020,
                budget_head: 'Other Comprehensive Income / Loss',
                budget: '20000'
            },
            {
                month: 'Aug',
                year: 2020,
                budget_head: 'Financial Expenses',
                budget: '20000'
            },
            {
                month: 'Sep',
                year: 2020,
                budget_head: 'Administrative Expenses',
                budget: '20000'
            },
            {
                month: 'OCT',
                year: 2020,
                budget_head: 'Cost of Sales',
                budget: '20000'
            },
            {
                month: 'Nov',
                year: 2020,
                budget_head: 'Cost of Sales',
                budget: '20000'
            },
            {
                month: 'Dec',
                year: 2020,
                budget_head: 'Cost of Sales',
                budget: '20000'
            },
        ],
        fields: [
          {
            key: "month",
            label: "Month",
          },
          {
            key: "year",
            label: "Year",
          },
          {
            key: "budget_head",
            label: "Budget Head",
          },
          {
            key: "budget",
            label: "Budget",
          },
        ],
        loading: false,
      };
    },
  
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "HVF Budget", route: "/hvf/budget" },
        ]);
    },
    created() {
        localStorage.setItem("tabIndex", 16);
        this.$root.$emit("call-active-menu", 16);
    },
  
  };
  </script>
  